const interiorData = [
  {
    src: "./images/Services/interior/1.jpg",
  },
  {
    src: "./images/Services/interior/2.jpg",
  },
  {
    src: "./images/Services/interior/3.jpg",
  },
  {
    src: "./images/Services/interior/4.jpg",
  },
  {
    src: "./images/Services/interior/5.jpg",
  },
  {
    src: "./images/Services/interior/6.jpg",
  },
  {
    src: "./images/Services/interior/7.jpg",
  },
  {
    src: "./images/Services/interior/8.jpg",
  },
  {
    src: "./images/Services/interior/9.jpg",
  },
  {
    src: "./images/Services/interior/10.jpg",
  },
  {
    src: "./images/Services/interior/11.jpg",
  },
  {
    src: "./images/Services/interior/12.jpg",
  },
  {
    src: "./images/Services/interior/13.jpg",
  },
  {
    src: "./images/Services/interior/14.jpg",
  },
  {
    src: "./images/Services/interior/15.jpg",
  },
  {
    src: "./images/Services/interior/16.jpg",
  },
  {
    src: "./images/Services/interior/17.jpg",
  },
  {
    src: "./images/Services/interior/18.jpg",
  },
  {
    src: "./images/Services/interior/19.jpg",
  },
  {
    src: "./images/Services/interior/20.jpg",
  },
];
export { interiorData };
