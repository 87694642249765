const provideData = [
  {
    img: "./images/Home/provide1.svg",
    text: "Short Timelines & Fast Execution",
  },
  {
    img: "./images/Home/provide2.svg",
    text: "Customised Designs",
  },
  {
    img: "./images/Home/provide3.svg",
    text: "Extra Storage & Floor Space",
  },
  {
    img: "./images/Home/provide4.svg",
    text: "Luxurious & Modular Furniture",
  },
];
export default provideData;
