const cafeData = [
  {
    src: "./images/Services/cafe/cafe2.jpg",
  },
  {
    src: "./images/Services/cafe/cafe4.jpg",
  },
  {
    src: "./images/Services/cafe/cafe5.jpg",
  },
  {
    src: "./images/Services/cafe/cafe6.jpg",
  },
  {
    src: "./images/Services/cafe/cafe9.jpg",
  },
  {
    src: "./images/Services/cafe/cafe10.jpg",
  },
  {
    src: "./images/Services/cafe/cafe11.jpg",
  },
];
export { cafeData };
