const data = [
  {
    question: "What is turn key project? ",
    answer:
      "Means we do the interior desin project from concept designs to drawings then whole execution and final hand over to clients.",
  },
  {
    question: "Why everyone says that Dreamcatcherzz work is Hassle free?",
    answer:
      "We believe in getting all the solutions to clients door steps through samples, catalogue and pictures and videos. So clients don't have to run around anywhere.",
  },
  {
    question:
      "How does the project timeline of Dreamcatcherzz are always short and the projects usually are done in time?",
    answer:
      "We finalise everything with the clients feom payments to materials, before starting any work on the site and our team and vendors are trained to be prompt and our process is very well planned in advance.",
  },
  {
    question: "Who are principal Designers of Dreamcatcherzz. ",
    answer:
      "Shilpa Rawat , Kashyap Rawat . Both are known in industry for their simple, elegant yet elegant and luxury designs.",
  },
];

export default data;
