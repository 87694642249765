const storeData = [
  {
    src: "./images/Services/store/s1.jpg",
  },
  {
    src: "./images/Services/store/s2.jpg",
  },
  {
    src: "./images/Services/store/s3.jpg",
  },
  {
    src: "./images/Services/store/s4.jpg",
  },
  {
    src: "./images/Services/store/s5.jpg",
  },
  {
    src: "./images/Services/store/s6.jpg",
  },
  {
    src: "./images/Services/store/s7.jpg",
  },
  {
    src: "./images/Services/store/s8.jpg",
  },
  {
    src: "./images/Services/store/s9.jpg",
  },
  {
    src: "./images/Services/store/s10.jpg",
  },
  {
    src: "./images/Services/store/s11.jpg",
  },
  {
    src: "./images/Services/store/s12.jpg",
  },
];
export { storeData };
